.bg__menu{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: fadeIn 0.5s linear;
    z-index: 1050;
    background-color: rgba(#000, 0.6);

    &.hide{
        animation: fadeOut 0.5s linear;
    }
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: 'poppinsmedium';
	font-size: 14px;
	line-height: 16px;
	width: 100%;
}
.menu__link{
	&:hover{
		text-decoration: underline;
	}
}
.btn__topo{
	display: inline-flex;
	align-items:center;
	background-color: #d62b26;
	color: #fff;
	justify-content: space-between;
	border: none;
	min-width: 160px;
	min-height: 26px;
	font-family: poppinsmedium;
	font-size: 12px;
	line-height: 16px;
	padding: 6px 15px;
	transition: all 0.3s linear;
	position: relative;
	overflow: hidden;

	&:after{
		position: absolute;
		content: '';
		height: 100%;
		border-radius: 0px;
		top: 0px;
		left: 0px;

		width: 0;
		background-color: rgba(255,255,255,0.4);
	}

	&:hover{
		background-color: #008fd4;
		color: #fff;
		text-decoration: none;

		&:after{
			width: 120%;
			  background-color: rgba(255,255,255,0);
			transition: all 0.4s ease-in-out;
		}
	}
}
.menu__item{
	&.menu__item--active{
		.menu__link{
			text-decoration: underline;
		}
	}
}
.topo{
	z-index: 1000;
}
.dropdown__controls{
	position: relative;

	button{
		width: 20px;
		height: 20px;
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		background-color: #fff;
		color: #d62b26;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		line-height: 12px;
		border-radius: 50%;
		border: 1px solid #d62b26;
		transition: all 0.3s linear;
		padding: 0px;
		padding-top: 2px;

		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}
.dropmenu{

	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		@include media-breakpoint-up (lg) {
			overflow-y: auto;
			max-height: calc(37px * 5);

			&::-webkit-scrollbar{
				width: 8px;
			}
			&::-webkit-scrollbar-thumb{
				background-color: #fff;
				border-radius: 4px;
			}
			&::-webkit-scrollbar-track{
				background-color: darken(#008fd4, 20%);
			}
		}

	}

	// li{
	// 	border-bottom: 1px solid #fff;
	// }

	a{
		display: block;
		width: 100%;
		padding: 10px;
		background-color: #008fd4;
		color: #fff;
		font-size: 14px;
		line-height: 16px;

		&:hover{
			color: #fff;
			text-decoration: none;
			background-color: darken(#008fd4, 10%);
		}
	}
}
.segura__contato__topo{
	display: flex;
	align-items: center;
	column-gap: 5px;

	.icon__mask{
		color: #d62b26;
	}

	.barrinha{
		margin: unset !important;
	}
}
.segura__sociais__topo{
	display: flex;
	align-items:center;
	justify-content: center;
	column-gap: 6px;
}
.sociais__nav{
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 5px;

	a{
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 24px;
		background-color: #008fd4;
		color: #fff;
		transition: all 0.3s linear;
		border-radius: 50%;

		&:hover{
			background-color: #d62b26;
			color: #fff;
			text-decoration: none;
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo__main{
		.container{
			display: flex;
			justify-content:space-between;
		}
	}
	.segura__codigo__btn{
		margin-left: 25px;
	}
	.main__right{
		max-width: 710px;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.main__cima{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 8px 0px;
	}
	.main__baixo{
		display: flex;
		flex-wrap: 1;
		width: 100%;
	}
	.main__menu{
		display: flex;
		justify-content: space-between;




		.menu__item{
			display: flex;

			.menu__link{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 140px;
			}
		}
		.dropdown__controls{
			display: flex;
			.drop__btn{
				display: none;
			}
			.menu__link{
				column-gap: 4px;
				&:after{
					border-top: 5px solid #000;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					content: '';
					display: inline-block;
				}
			}
		}
		.menu__item{
			position: relative;
			&:hover{
				.dropmenu{
					display: block;
				}
			}
		}
		.dropmenu{
			position: absolute;
			top: 100% !important;
			left: calc(50% - 100px);
			width: 200px;
			max-height: 300px;
			overflow-y: auto;
			background-color: #fff;
			list-style: none;
			padding-left: 0px;
			margin-bottom: 0px;
			box-shadow: 0px 0px 8px rgba(#000, 0.4);
			margin: 0px !important;
			z-index: 1000;

			li{
				display: flex;

				&:last-child{
					a{
						border-bottom: none;
					}
				}

				a{
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					width: 100%;
					text-align: center;
					color: #fff;
					transition: all 0.3s linear;
					font-family: 'poppinsregular';
				}
			}
		}
	}
	.topo__mobile{
		display: none;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.5s linear;
		background-color: #fff;
		z-index: 1080;

		.main__right{
			display: flex;
			flex-direction: column;

			.main__cima{
				order: 2;
				padding: 15px 0px;
			}
			.main__baixo{
				order: 1;
			}
		}
		.segura__codigo__btn{
			display: flex;
			justify-content: center;
			padding: 10px;
		}
		.segura__sociais__topo{
			flex-direction: column;
			align-items: center;
			text-align: center;
			row-gap: 5px;
		}

		.main__logo{
			display: block;
			text-align: center;
			padding: 5px;
			margin-bottom: 10px;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
	.menu__link{
		padding: 10px;
		display: block;
	}
	.topo__mobile{
		color: #fff;
		padding: 10px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.mbl__logo{
		max-width: 70px;
		width: 100%;
	}
	.menu__item{
		&.menu__item--active{
			.menu__link{
				background-color: #008fd4;
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	.segura__contato__topo{
		flex-direction: column;
		align-items: center;
		row-gap: 5px;

		.barrinha{
			display: none;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: #fff;
		font-size: 14px;
		line-height: 14px;
		color: #000;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		font-size: 20px;
		line-height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
