#carousel_quem{

  
	.carousel-controls{

		.carousel-control-prev,
		.carousel-control-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			background-color: red;
			border-radius: 50%;
			opacity: 1;
			position: absolute;
			top: calc(50% - 18px);
		}
	}
}
  

.especialiadade{
	padding-top: 60px;
}
.referencia{
	padding-top: 60px;
}
.banner__video{
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.qualidade{
	margin-top: 40px;
	overflow: hidden;
}
.qualidade__segura__img{
	@include media-breakpoint-up (lg) {
		min-height: 500px;
		position: relative;

		.carousel-item{
			img{
				position: absolute;
				top: 0px;
				right: 0px;
				max-width: unset;
			}
		}
	}
}

.owl-carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			min-width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #0090d4;
			color: #fff;
			transition: all 0.3s linear;
			border: none;

			&:hover{
				background-color: #d62b26;
				color: #fff;
				text-decoration: none;
			}
		}
		button.owl-prev{
			left: -15px;
		}
		button.owl-next{
			right: -15px;
		}
	}
}

.produto__card{
	display: block;
	width: 100%;
	max-width: 255px;
	margin-left: auto;
	margin-right: auto;
	&:hover{
		text-decoration: none;
		color: #fff;

		.produto__card__conteudo{
			background-color: #d62b26;
		}
	}
	.produto__card__img{
		background-color: #ededed;
		margin-bottom: 8px;
	}
	.produto__card__setinha{
		min-width: 12px;
	}
	.produto__card__conteudo{
		padding: 15px 16px;
		background-color: #0090d4;
		color: #fff;
		transition: all 0.3s linear;
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 10px;
	}
	.produto__card__titulo{
		font-family: poppinssemibold;
		font-size: 15px;
		line-height: 18px;
		margin-bottom: 2px;
		--linhas: 1;
	}
	.produto__card__sub{
		font-size: 13px;
		line-height: 16px;
		--linhas: 1;
	}
}

.btn__center{
	padding-top: 40px;
	display: flex;
	justify-content: center;
}

.btn__conteudo{
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
	padding: 14px 20px;
	font-family: poppinsmedium;
	font-size: 13px;
	line-height: 15px;
	border: none;
	transition: all 0.3s linear;
	justify-content: space-between;
	min-width: 215px;
	min-height: 40px;
	background-color: #0090d4;
	color: #fff;
	position: relative;
	overflow: hidden;

	&:after{
		position: absolute;
		content: '';
		height: 100%;
		border-radius: 0px;
		top: 0px;
		left: 0px;

		width: 0;
		background-color: rgba(255,255,255,0.4);
	}

	&:hover{
		background-color: #d62b26;
		text-decoration: none;
		color: #fff;

		&:after{
			width: 120%;
			  background-color: rgba(255,255,255,0);
			transition: all 0.4s ease-in-out;
		}
	}

	&.alt{
		background-color: #fff;
		color: #0090d4;

		&:hover{
			background-color: #d62b26;
			text-decoration: none;
			color: #fff;
		}
	}
}

.titulo__espec{
	font-family: poppinsmedium;
	font-size: 35px;
	line-height: 39px;
	color: #292926;
	max-width: 570px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;

	@include media-breakpoint-down (sm) {
		font-size: 26px;
		line-height: 30px;
	}
}
.titulo__ref{
	font-family: poppinsmedium;
	font-size: 35px;
	line-height: 39px;
	color: #292926;
	max-width: 660px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;

	@include media-breakpoint-down (sm) {
		font-size: 26px;
		line-height: 30px;
	}
}

.ref__grid{
	display: flex;
	justify-content: center;
	column-gap: 45px;
	row-gap: 45px;
	align-items: flex-start;
	flex-wrap: wrap;

	& > *{
		max-width: 230px;
		width: 100%;
	}
}
.ref__item{
	text-align: center;
}
.ref__numero{
	font-family: anton;
	color: #d62b26;
	font-size: 50px;
	line-height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1px;
}

.ref__titulo{
	font-family: poppinssemibold;
	font-size: 20px;
	line-height: 24px;
}

.qualidade{
	.row{
		align-items: center;

		.col-lg-6{
			&:nth-child(2) {
				padding-top: 15px;
				padding-bottom: 15px;

				@include media-breakpoint-down (md) {
					padding: 30px 15px;
				}
			}
		}
	}

	@include media-breakpoint-down (md) {
		.col-lg-6{
			&:nth-child(1) {
				order: 2;
			}
			&:nth-child(2) {
				order: 1;
			}
		}
	}
}

.explore{
	background-color: #0090d4;
	color: #fff;

	.col-lg-6{
		&:nth-child(1) {
			padding-top: 30px;
			padding-bottom: 30px;
			align-self: center;


		}
		&:nth-child(2) {
			padding-top: 30px;
		}
	}
}

.explore__titulo,
.qualidade__titulo{
	font-family: poppinsmedium;
	font-size: 35px;
	line-height: 38px;
	margin-bottom: 20px;

	@include media-breakpoint-down (sm) {
		font-size: 24px;
		line-height: 28px;
	}
}

.qualidade__btn,
.explore__btn{
	padding-top: 15px;
}

.explore__img{
	text-align: center;
}
